:root {
  --cor_principal: #1665F1;
  --cor_secundaria: #222222;
}

$cor_principal: #1665F1;
$cor_secundaria: #222222;

$primary: $cor_principal;

$custom-colors: (
  "cor_principal": $cor_principal,
  "cor_secundaria": $cor_secundaria,
);