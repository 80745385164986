* {
  font-family: 'Satoshi';
}

body {
  margin: 0;
  padding: 0;
}

.app-progress{
  height: 2px;
  background: rgba(237, 114, 32, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1090;
}
.app-progress::after{
  content: '';
  width: 25%;
  height: 2px;
  background: rgba(237, 114, 32,  1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1091;
  animation: app-loading 1s ease-in-out infinite alternate;
}

@keyframes app-loading {
  0% {
    left: 0;
  }
  100% {
    left: 75%;
  }
}

.layout{
  background-color: $gray-100;
}

// #sidebar {
//   max-height: 100vh;
//   background-color: $sidebar-bg-color !important;
//   background-image: url("../../public/sidebar_background.png");
//   background-size: contain;
//   background-repeat: no-repeat;
//   background-position: bottom;
// }

// #sidebar .navbar-brand img {
//   max-height: 40px;
// }

// #sidebar hr {
//   opacity: 0;
//   border-top-width: 2px;
//   border-top-color: $sidebar-border-color;
// }

// #sidebar-user-dropdown>div {
//   width: 32px;
//   height: 32px;
//   overflow: "hidden";
// }

// #sidebar-user-dropdown>div>img {
//   width: 32px;
//   height: 32px;
//   object-fit: cover;
// }

// .topbar {
//   border-bottom-width: 2px;
//   border-bottom-style: solid;
//   border-bottom-color: $topbar-border-color !important;
//   background-color: $topbar-bg-color;
// }

.form-floating>.form-control::placeholder {
  color: revert !important;
}
.form-floating>.form-control:not(:focus)::placeholder {
  color: transparent !important;
}
.form-floating > label::after{
  background-color: transparent !important;
}

.form-file:not(.disabled) {
  cursor: pointer;
}
.form-file:hover {
  background-color: #f8f9fa;
}

// .list-card {
//   border-bottom-width: 1px !important;
// }

// .list-card:hover {
//   background-color: $card-hover-color;
// }

// .list-card:active {
//   background-color: $card-active-color;
// }

.skeleton::after {
  animation: skeleton-loading 2s linear infinite alternate;
}

.skeleton-text {
  visibility: hidden;
  position: relative;
}

.skeleton-text::after {
  visibility: visible;
  position: absolute;
  content: ' ';
  width: 90%;
  height: 90%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: $border-radius;
}

.skeleton-text-title::after {
  width: 70%;
}

.skeleton-text-content::after {
  width: 90%;
}

.skeleton-background {
  visibility: hidden;
  position: relative;
}

.skeleton-background::after {
  visibility: visible;
  position: absolute;
  content: ' ';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.skeleton-avatar {
  visibility: hidden;
  position: relative;
}

.skeleton-avatar::after {
  visibility: visible;
  position: absolute;
  content: ' ';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 100%;
}

.skeleton-button {
  visibility: hidden;
  position: relative;
}

.skeleton-button::after {
  visibility: visible;
  position: absolute;
  content: ' ';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: $border-radius;
  border: none !important;
}

.skeleton-circle {
  visibility: hidden;
  position: relative;
}

.skeleton-circle::after {
  visibility: visible;
  position: absolute;
  content: ' ';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 100%;
}
.skeleton-rounded{
  visibility: hidden;
  position: relative;
}
.skeleton-rounded::after {
  visibility: visible;
  position: absolute;
  content: ' ';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: $border-radius;
}

@keyframes skeleton-loading {
  0% {
    background-color: rgba($black, .05);
  }

  20% {
    background-color: rgba($black, .05);
  }

  50% {
    background-color: rgba($black, .1);
  }

  80% {
    background-color: rgba($black, .05);
  }

  100% {
    background-color: rgba($black, .05);
  }
}

.react-datepicker {
  border-width: $border-width !important;
  border-color: $border-color !important;
  border-radius: $border-radius !important;
}

.react-datepicker__header {
  background-color: $card-bg;
}

.react-datepicker__navigation {
  margin-top: $spacer * 0.5;
}

.react-datepicker__header {
  border-bottom-color: $border-color;
}

.react-datepicker__current-month {
  font-weight: normal;
}

.react-datepicker__navigation-icon::before {
  top: 12px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2.1rem;
}

.input-group .react-datepicker-wrapper {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group .react-datepicker-wrapper .form-control {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rct-icon {
  min-width: 24px;
}

.react-checkbox-tree ol ol {
  padding-left: 32px;
}

.form-control.disabled {
  background-color: $input-disabled-bg !important;
}

.line-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.line-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.line-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-underline {
  border-bottom: 2px solid $primary;
}

.floating-select:not(:has(.react-select__placeholder)) > label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.floating-date-picker label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.floating-date-picker .react-datepicker-wrapper{
  width: 100%;
}

.floating-date-picker .react-datepicker__input-container>input {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
  padding: 1rem 20px;
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.floating-date-picker .react-datepicker-popper{
  z-index: 1000;
}
.floating-date-picker .react-datepicker__header{
  overflow: hidden;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
} 

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  top: -$border-width;
  border-bottom-color: $border-color;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-bottom-color: white;
}
.react-datepicker__day--in-selecting-range{
  background-color: #E9F9C7 !important;
}

.dropdown-no-caret::after {
  display: none !important;
}
.dropdown-menu {
  overflow: hidden;
}

.card-with-border{
  border-color: #e6e6e6 !important;
  border-width: 2px !important;
  border-radius: 20px !important;
}
.card-with-border .card-body{
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.tab-option{
  color: #6c757d;
  border-bottom-style: solid;
  border-bottom-color: #A9D941;
  border-bottom-width: 0;
  cursor: pointer;
}
.tab-option.active{
  color: #272F31;
  border-bottom-width: 4px;
}
.tab-option:hover{
  color: #272F31;
}

// $navbar-light-color:                rgba($white, .55);
// $navbar-light-hover-color:          rgba($white, .7);
// $navbar-light-active-color:         rgba($white, .9);
// $navbar-light-disabled-color:       rgba($white, .3);

.mentions__input {
  @extend .form-control;
}
.mentions__input:disabled {
  background-color: #e9ecef !important;
}
.mentions__control{
  min-height: 86px;
}
.mentions__highlighter{
  padding: 0.375rem 0.75rem;
  color: #016db7 !important;
}
.mentions__highlighter > strong, .mentions__highlighter__caret{
  position: relative;
  z-index: 1;
  pointer-events: none;
}
.mentions__suggestions{
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.175);
  box-shadow: 0 0.5rem 1rem rgba(173, 181, 189, 0.15);
  z-index: 3 !important;
}

.mentions__suggestions__item{
  padding: 5px 15px;
  text-align: left;
}
.mentions__suggestions__item--focused{
  background-color: #DEEBFF;
}

.xw-100 *{
  max-width: 100% !important;
}

.form-check{
  padding-left: 2.5em !important;
}
.form-check .form-check-input {
  margin-left: -2.5em !important;
}

.form-check.sm{
  padding-left: 1.5em !important;
}
.form-check.sm .form-check-input{
  width: 1em;
  height: 1em;
  margin-left: -1.5em !important;
  margin-top: 0.25em;
}

.opacity-hover{
  opacity: 0.5;
  transition: all 0.5s linear;
}
.opacity-hover:hover{
  opacity: 1;
}