$body-bg: $white;

$card-bg: $white;
// $card-cap-bg: transparent;
// $card-border-color: #e6e6e6;
// $card-border-width: 0;
// $card-border-radius: 20px;
// $card-hover-color:          rgba($white, .7);
// $card-active-color:         rgba($white, .9);
// $card-spacer-x: 0;

// $sidebar-bg-color: $dark-green;
// $sidebar-border-color: $dark-green;
// $sidebar-item-border-color: #f2f4f6;

$topbar-bg-color: transparent;
$topbar-border-color: #edeff2;

$toast-background-color:            rgba($white, 1);
$toast-header-background-color:     rgba($white, 1);

$form-check-input-width:  2em;
$form-switch-width:       4em;
$form-check-input-border: 2px solid #5D6365;

// $input-bg: transparent;
// $input-border-color: #5D6365;
// $input-border-width: 2px;
// $input-group-addon-border-color: #5D6365;

$border-radius: 20px;
$input-border-radius: 20px;
$btn-border-radius: 20px;
$btn-border-radius-lg: 20px;
$form-floating-padding-x: 20px;
$btn-padding-y-lg: 18px;
$alert-border-radius: 20px;

$modal-header-border-color: #e6e6e6;
$modal-footer-border-color: #e6e6e6;

.fw-medium {
  font-weight: 500;
};

// $box-shadow:                  0 .5rem 1rem rgba($gray-500, .15);
// $box-shadow-sm:               0 .125rem .25rem rgba($gray-500, .075);
// $box-shadow-lg:               0 1rem 3rem rgba($gray-500, .175);
// $box-shadow-inset:            inset 0 1px 2px rgba($gray-500, .075);

$modal-backdrop-opacity: 0.7;