@import "./styles/bootstrap.scss";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "./styles/colors.scss";
@import "./styles/style.scss";
@import "./styles/fonts.scss";

$theme-colors: map-merge($theme-colors, $custom-colors);

@import "~bootstrap/scss/bootstrap";
@import "~react-datepicker/dist/react-datepicker.min.css";
@import "~react-image-crop/dist/ReactCrop.css";